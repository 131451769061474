
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;

}

body{
	height: 100vh;
	width: 100%;
}


.left:hover{
  filter: blur(0);
}

.right{
	padding: 25px 40px;
}


h2{
	position: relative;
	padding: 0 0 10px;
	margin-bottom: 10px;
}

.contactsContainer h2:after{
	content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: #2ecc71;
}

.field{
	width: 100%;
	border: 1px solid #CCCCCC;
		border-radius: 0.375rem;
		padding: 0.5rem;
	
	outline: none;
	
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	margin-bottom: 22px;
	transition: .3s;
}

.field:hover{
	background-color: rgba(0, 0, 0, 0.1);
}

textarea{
	min-height: 150px;
}

.btn{
	width: 25%;
	padding: 0.5rem 1rem;
	background-color: #2ecc71;
	color: #fff;
	font-size: 1.1rem;
	border: none;
	outline: none;
	cursor: pointer;
	transition: .3s;
}

.btn:hover{
    background-color: #27ae60;
}

.field:focus{
    border: 2px solid rgba(30,85,250,0.47);
    background-color: #fff;
}
.location-search-input {
	border: 1px solid #e2e8f0;
  }
  
  .autocomplete-dropdown-container {
	border: 1px solid }

	
	.map-container {
		width: 100%;
		height: 500px; /* default height */
	  }
 @media screen and (max-width: 768px) {
		.map-container {
		  height: 100%;/* smaller height for screens less than 768px */
		}
	  }
@media screen and (max-width: 880px){
	.contact-box{
		grid-template-columns: 1fr;
	}
	.left{
		height: 200px;
	}
}