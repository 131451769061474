@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Pinstripe:ital@1&family=Cedarville+Cursive&family=Oxygen&family=Qwitcher+Grypen:wght@700&family=Roboto:wght@300&display=swap');
*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
  
  /* overflow: hidden; */
}
body{
  padding-top: 190px;
font-family: "Poppins", sans-serif;
background-color: #fff;
}
.container{
  max-width: 80%;
  margin: auto;
}

.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a{
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}
ul{
  list-style: none;
}
/* button{
  border-radius: 5px;
  padding: 17px 30px;
  background-color: #27ae60;
  border: none;
  color: #fff;
  font-weight: bold;


} */
button i {
  margin-right: 5px;
}