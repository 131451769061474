
.bg-bubbles {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .bubble {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation-name: float;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  .bubble1 {
    width: 50px;
    height: 50px;
    top: 20%;
    left: 10%;
    animation-delay: 0s;
  }
  
  .bubble2 {
    width: 70px;
    height: 70px;
    top: 50%;
    left: 20%;
    animation-delay: 2s;
  }
  
  .bubble3 {
    width: 100px;
    height: 100px;
    top: 80%;
    left: 80%;
    animation-delay: 1s;
  }
  
  @keyframes float {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(0, -200%);
    }
  }
  