 /* .banner {
    background-image:url("../../../assets/discount1.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  } */
  .banner .container {
    padding-top: 15%;
  }
  .tech{
    min-height: 10vh;
    display: grid;
    place-items: center;
    }
    .slider{
        height: 250px;
        margin: auto;
        overflow: hidden;
        position: relative;
        display: grid;
        width: 90%;
        place-items: center;
    }
    .slide-track {
        display: flex;
        width: calc(250px * 18);
        animation: scroll 40s linear infinite;
    }
    .slide-track:hover{
        animation-play-state: paused;
    }
    @keyframes scroll {
        0%{
            transform: translateX(0);
        }
        100%{
            transform: translateX(calc(-250px * 9));
        }
        
    }
    .slide{
        height: 120px;
        width: 400px;
        display: flex;
        align-items: center;
        /* padding: 15px; */
        perspective: 100px;
        /* to make image move on z axis(depth) */
    }
    img{
        width: 100%;
        transition: transform 1s;
    }
    img:hover{
        transform: translateZ(20px);
    }
    
    /* Gradient shadows */
    .slider::before,
    .slider::after{
        background: linear-gradient(to right, rgba(255, 255, 255,1)0%,
         rgba(255, 255, 255, 0)100%);
         content: '';
         height: 100%;
         position: absolute;
         width: 15%;
         z-index: 2;
    }
    .slider::before{
        left: 0;
        top: 0;
    }
    .slider::after{
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }